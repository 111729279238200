<template>
  <svg @click="handleClick" class="playBtn" 
    width="30" height="30" viewBox="0 0 60 60" fill="none" 
    stroke="black" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="28.5"  stroke-width="3"/>
    <path d="M50 30L18.5 48.1865L18.5 11.8135L50 30Z" fill="black"/>
  </svg>

</template>

<script>

export default {
  setup(props, { emit })  {
    const handleClick = () => {
      emit('play')
    }
    return {
      handleClick // functions returned behave the same as methods
    }
  }  
}
</script>

<style>

</style>