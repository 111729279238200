<template>
  <AudioPlayer />
</template>

<script>

import AudioPlayer from './components/AudioPlayer.vue'

export default {
  name: 'AudioPlayerApp',
  components: {  
    AudioPlayer
  }
}
</script>

<style>

</style>
